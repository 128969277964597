import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import "./NotFound.css";

const NotFound = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/"); // Редирект на главную страницу
  };

  return (
    <div className="not-found1-container1">
      <Helmet>
        <title>Page Not Found - 404</title>
      </Helmet>
      <h3 className="not-found1-heading">Oops! Page Not Found</h3>
      <div className="not-found1-container2">
        <h1 className="not-found1-text2">404</h1>
      </div>
      <div className="not-found1-container3">
        <h2 className="not-found1-text3">
          We're sorry, but the page you requested does not exist.
        </h2>
        <button className="not-found1-button" onClick={handleGoHome}>
          Go to Home
        </button>
      </div>
    </div>
  );
};

export default NotFound;
