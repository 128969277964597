import React from "react";
import { Helmet } from "react-helmet";
import { ThemeProvider } from "@material-tailwind/react";
import { NavbarBlock } from "../components/Navbar";
import { HeroBlock } from "../components/Hero";
import { FooterWithSocialLinks } from "../components/Footer";
import { AboutUsBlock } from "../components/AboutUs";
import { PartnersCarousel } from "../components/PartnersCarousel";
import { PricingBlock } from "../components/Pricing";

const HomePage = () => {
  return (
    <ThemeProvider>
      <div className="w-full flex flex-col min-h-screen items-center">
        <Helmet>
          <title>Покупки за границей без границ</title>
        </Helmet>
        <NavbarBlock />
        <HeroBlock />
        <AboutUsBlock />
        <PricingBlock />
        <PartnersCarousel />
        <FooterWithSocialLinks />
      </div>
    </ThemeProvider>
  );
};

export default HomePage;
