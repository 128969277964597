import {createRoot} from "react-dom/client";
import {
    BrowserRouter as Router,
    Navigate,
    Route,
    Routes,
} from "react-router-dom";

import "./index.css";
import NotFound from "./pages/NotFound";
import HomePage from "./pages/HomePage";
import {DevSupport} from "@react-buddy/ide-toolbox";
import {ComponentPreviews, useInitial} from "./dev";

const Index = () => {
    return (
        <Routes>
            <Route path="/" element={<HomePage/>}/>
            <Route path="/not-found" element={<NotFound/>}/>
            <Route path="*" element={<Navigate to="/not-found" replace/>}/>
        </Routes>
    );
};

// Use createRoot instead of ReactDOM.render
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <Router>
        <DevSupport ComponentPreviews={ComponentPreviews}
                    useInitialHook={useInitial}
        >
            <Index/>
        </DevSupport>
    </Router>,
);