import React from "react";
import "./PartnersCarousel.css";
// Импорт изображений с корректными путями
import amazon from "../images/amazon.png";
import apple from "../images/apple.png";
import bofa from "../images/bofa.png";
import cat from "../images/cat.png";
import deel from "../images/deel.png";
import ebay from "../images/ebay.png";
import garmin from "../images/garmin.png";
import paypal from "../images/paypal.png";
import revolut from "../images/revolut.png";
import wire from "../images/wire.png";
import wise from "../images/wise.png";
import zelle from "../images/zelle.png";

const ourRecommendations = [
  { name: "AmazonIco", image: amazon },
  { name: "AppleIco", image: apple },
  { name: "Bank of America Ico", image: bofa },
  { name: "CatIco", image: cat },
  { name: "DeelIco", image: deel },
  { name: "eBayIco", image: ebay },
  { name: "GarminIco", image: garmin },
  { name: "PayPalIco", image: paypal },
  { name: "RevolutIco", image: revolut },
  { name: "WireIco", image: wire },
  { name: "WiseIco", image: wise },
  { name: "ZelleIco", image: zelle },
];

export function PartnersCarousel() {
  return (
    <div className="overflow-hidden">
      <div className="w-full bg-white py-4 sm:py-6 font-[Playfair] animate-slide-up">
        <h2 className="text-center text-4xl font-bold mb-12">
          Краткий перечень сайтов и сервисов, с которыми мы работаем:
        </h2>
        <div className="relative w-full overflow-hidden">
          <div className="flex gap-12 partners-animate-scroll">
            {/* Дублируем иконки для плавного бесконечного скролла */}
            {Array(3)
              .fill(ourRecommendations)
              .flat()
              .map((partner, index) => (
                <div
                  key={index}
                  className="flex-shrink-0 w-32 h-32 p-4 bg-neutralLight rounded-6 shadow-md hover:shadow-lg transform hover:scale-105 transition-all duration-300 flex items-center justify-center"
                >
                  <img
                    src={partner.image}
                    alt={partner.name}
                    className="w-28 h-28 object-contain"
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
