import {
  CreditCardIcon,
  GlobeAmericasIcon,
  ShieldCheckIcon,
  ShieldExclamationIcon,
} from "@heroicons/react/24/solid";

const features = [
  {
    name: "Трансграничные денежные переводы",
    description:
      "Организуем быстрые и надежные переводы между Россией, США, странами ЕС и другими регионами напрямую, без посредников с помощью собственных платежных решений.",
    icon: GlobeAmericasIcon,
  },
  {
    name: "Оплата зарубежных товаров и услуг",
    description:
      "Осуществляем оплату товаров и услуг за пределами России, включая оплату автозапчастей, отелей и прочее.",
    icon: CreditCardIcon,
  },
  {
    name: "Высокая репутация и надежность",
    description:
      "Мы работаем на рынке международных платежей с апреля 2022 года и за это время заслужили доверие сотен клиентов и партнеров.",
    icon: ShieldCheckIcon,
  },
  {
    name: "Безопасность и конфиденциальность",
    description: "Обеспечиваем полную конфиденциальность каждого платежа.",
    icon: ShieldExclamationIcon,
  },
];

export function HeroBlock() {
  return (
    <div className="w-full bg-white py-8 sm:py-12">
      <div className="mx-auto max-w-7xl px-8 lg:px-12 font-[Playfair] animate-slide-up">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-xl font-semibold text-teal-300">OPLATI.STORE</h2>
          <p className="mt-2 text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl">
            Ваши покупки за границей без границ
          </p>
          <p className="mt-6 text-lg text-gray-700">
            Сервис мгновенных конфиденциальных платежей за рубежом — оплачивайте
            товары/услуги, бронируйте жильё и покупайте билеты с минимальной
            комиссией.
          </p>
        </div>
        <div className="mx-auto mt-8 max-w-4xl sm:mt-10 lg:mt-12 lg:max-w-6xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-16 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-lg font-semibold text-gray-900">
                  <div className="absolute left-0 top-2.5 flex h-10 w-10 items-center justify-center rounded-lg bg-teal-300">
                    <feature.icon
                      aria-hidden="true"
                      className="h-6 w-6 text-white"
                    />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base text-gray-700">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
