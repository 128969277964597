import CountUp from "react-countup";

const stats = [
  {
    id: 1,
    name: "Выполненных сделок",
    value: 13000,
    suffix: "+",
    bgColor: "bg-blue-100",
    textColor: "text-blue-900",
  },
  {
    id: 2,
    name: "Довольных клиентов",
    value: 600,
    suffix: "+",
    bgColor: "bg-green-100",
    textColor: "text-green-900",
  },
  {
    id: 3,
    name: "Клиентов приходят по рекомендации",
    value: 84,
    suffix: "%",
    bgColor: "bg-yellow-100",
    textColor: "text-yellow-900",
  },
  {
    id: 4,
    name: "Конфиденциально",
    value: 100,
    suffix: "%",
    bgColor: "bg-purple-100",
    textColor: "text-purple-900",
  },
  {
    id: 5,
    name: "Стран",
    value: 128,
    suffix: "+",
    bgColor: "bg-pink-100",
    textColor: "text-pink-900",
  },
  {
    id: 6,
    name: "Среднее время обработки одной транзакции",
    value: 13,
    suffix: "мин",
    bgColor: "bg-red-100",
    textColor: "text-red-900",
  },
];

export function AboutUsBlock() {
  return (
    <div className="w-full bg-white py-8 sm:py-12">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 flex flex-col lg:flex-row gap-x-4 font-[Playfair] animate-slide-up">
        {/* Левая часть с текстом "О нас" */}
        <div className="lg:w-1/2 px-8 mb-8 lg:mb-0 text-center lg:text-left">
          <h2 className="text-4xl font-bold mb-4">
            Мы – компания с российскими корнями, официально зарегистрированная в
            США 28 апреля 2022 года.
          </h2>
          <p className="text-lg mb-6">
            Основная миссия нашего бизнеса – предоставление юридическим и
            физическим лицам надежного сервиса для осуществления трансграничных
            платежей.
          </p>
          <p className="text-lg mb-6">
            Нашей целью является устранение границ для Вас и Вашего бизнеса,
            облегчая доступ к международному рынку с помощью быстрых и
            безопасных платежных решений.
          </p>
          <p className="text-lg">
            В каждую операцию мы вкладываем наш экспертный опыт и личные
            технологические и финансовые решения. Используя прямые платежные
            каналы, мы избегаем необходимости в посредниках, что позволяет нам
            не только ускорить процессинг транзакций, но и повысить уровень
            конфиденциальности и снизить издержки для наших клиентов.
          </p>
        </div>

        {/* Правая часть с отображением статистики */}
        <div className="lg:w-1/2 flex items-stretch">
          <dl className="grid gap-2 text-center auto-rows-fr grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 flex-grow">
            {stats.map((stat) => (
              <div
                key={stat.id}
                className={`flex flex-col items-center justify-center gap-y-2 p-4 rounded-lg ${stat.bgColor} ${stat.textColor} h-full`}
              >
                <dt className="text-base">{stat.name}</dt>
                <dd className="order-first text-2xl font-semibold tracking-tight sm:text-3xl">
                  <CountUp
                    end={stat.value}
                    duration={3}
                    suffix={stat.suffix}
                    startOnMount={true}
                  />
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
