import { CheckIcon } from "@heroicons/react/20/solid";

const tiers = [
  {
    name: "Тарифы на оплату картой",
    id: "credit-card-payments",
    href: "#",
    priceMonthly: "2.5% - 4.5%",
    description: "Минимум $100",
    features: [],
    featured: true,
    gradient: "bg-gradient-to-r from-indigo-300 to-purple-400",
    shadowColor: "shadow-[0_10px_15px_-3px_rgba(99,102,241,0.25)]",
  },
  {
    name: "Тарифы на оплату через PayPal",
    id: "paypal-payments",
    href: "#",
    priceMonthly: "1.8% - 5%",
    description: "Минимум $100",
    features: [],
    featured: true,
    gradient: "bg-gradient-to-r from-blue-300 to-indigo-400",
    shadowColor: "shadow-[0_10px_15px_-3px_rgba(59,130,246,0.25)]",
  },
  {
    name: "Тарифы на оплату инвойсов переводом по реквизитам",
    id: "invoice-payments",
    href: "#",
    priceMonthly: "2.3% - 5%",
    description: "Минимум $5,000",
    features: [],
    featured: true,
    gradient: "bg-gradient-to-r from-pink-300 to-yellow-400",
    shadowColor: "shadow-[0_10px_15px_-3px_rgba(236,72,153,0.25)]",
  },
];

export function PricingBlock() {
  return (
    <div className="px-6 py-12 sm:py-16 lg:px-8 font-[Playfair] animate-slide-up">
      <div className="mx-auto max-w-4xl text-center">
        <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          Наши тарифы
        </h2>
        <p className="mt-4 text-lg text-gray-600">
          Текущий курс доллара = 100₽
        </p>
      </div>
      <div className="mx-auto mt-16 grid max-w-lg grid-cols-1 gap-y-12 sm:gap-x-6 lg:max-w-none lg:grid-cols-3">
        {tiers.map((tier) => (
          <div
            key={tier.id}
            className={`flex flex-col overflow-hidden rounded-lg bg-white border border-gray-200 ${tier.shadowColor} transform lg:scale-105 transition-transform duration-300`}
          >
            {/* Добавляем узкую градиентную полоску с названием тарифа */}
            <div className={`${tier.gradient} text-white text-center py-2`}>
              <span className="text-sm font-semibold">{tier.name}</span>
            </div>
            <div className="flex-1 px-6 py-8 text-center sm:p-10 sm:pb-6">
              <h3 className="text-lg font-semibold leading-6 text-gray-900">
                {tier.name}
              </h3>
              <div className="mt-4 flex items-baseline justify-center text-5xl font-bold tracking-tight text-gray-900">
                <span>{tier.priceMonthly}</span>
              </div>
              <p className="mt-4 text-sm text-gray-500">{tier.description}</p>
            </div>
            <div className="bg-gray-50 px-6 py-8 sm:p-10 sm:pt-6">
              <ul role="list" className="space-y-4">
                {tier.features.map((feature) => (
                  <li key={feature} className="flex items-center">
                    <CheckIcon
                      className="h-5 w-5 text-indigo-500"
                      aria-hidden="true"
                    />
                    <span className="ml-3 text-base text-gray-700">
                      {feature}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-8 text-center text-sm text-gray-500">
        *Конечный процент комиссии устанавливается исходя из периодичности и
        суммы заказов. При необходимости рассчитать сумму в рублях, вы можете
        связаться с нами в Telegram или WhatsApp.
      </div>
    </div>
  );
}
