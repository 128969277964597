import React from "react";
import {
  Collapse,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Navbar,
  Typography,
} from "@material-tailwind/react";
import {
  Bars3Icon,
  ChevronDownIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  BellAlertIcon,
  BriefcaseIcon,
  CurrencyDollarIcon,
  CurrencyEuroIcon,
  ShoppingCartIcon,
  TicketIcon,
} from "@heroicons/react/24/solid";

const ourServicesMenuItems = [
  {
    title: "Международные переводы",
    description: "Переводы между РФ и другими странами.",
    icon: CurrencyDollarIcon,
    href: "/international-transfers",
  },
  {
    title: "Оплата автозапчастей",
    description: "Оплата автозапчастей через наши сервисы.",
    icon: CurrencyEuroIcon,
    href: "/auto-parts-payment",
  },
  {
    title: "Оплата товаров",
    description: "Опции оплаты товаров и доступные сервисы.",
    icon: ShoppingCartIcon,
    href: "/product-payment",
  },
  {
    title: "Оплата услуг",
    description: "Помощь в оплате услуг по всему миру.",
    icon: BriefcaseIcon,
    href: "/service-payment",
  },
  {
    title: "Оплата отелей и билетов",
    description: "Бронирование отелей и билетов.",
    icon: TicketIcon,
    href: "/hotel-ticket-payment",
  },
  {
    title: "Контакты",
    description: "Свяжитесь с нами для консультаций.",
    icon: BellAlertIcon,
    href: "/contact",
  },
];

function NavListMenu() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
  const renderItems = ourServicesMenuItems.map(
    ({ icon, title, description, href }, key) => (
      <a href={href} key={key}>
        {" "}
        {/* Используем href из массива */}
        <MenuItem className="flex items-center gap-3 rounded-lg">
          <div className="flex items-center justify-center rounded-lg !bg-blue-gray-50 p-2 ">
            {" "}
            {React.createElement(icon, {
              strokeWidth: 2,
              className: "h-6 text-gray-900 w-6 text-teal-300",
            })}
          </div>
          <div>
            <Typography
              variant="h6"
              color="blue-gray"
              className="flex items-center text-base font-semibold"
            >
              {title}
            </Typography>
            <Typography
              variant="paragraph"
              className="text-sm !font-medium text-blue-gray-500"
            >
              {description}
            </Typography>
          </div>
        </MenuItem>
      </a>
    ),
  );

  return (
    <React.Fragment>
      <Menu
        open={isMenuOpen}
        handler={setIsMenuOpen}
        offset={{ mainAxis: 20 }}
        placement="bottom"
        allowHover={true}
      >
        <MenuHandler>
          <Typography as="div" variant="lead" className="font-medium">
            <ListItem
              className="flex items-center gap-2 py-2 pr-4 font-medium text-gray-900"
              selected={isMenuOpen || isMobileMenuOpen}
              onClick={() => setIsMobileMenuOpen((cur) => !cur)}
            >
              Услуги
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`hidden h-3 w-3 transition-transform lg:block ${
                  isMenuOpen ? "rotate-180" : ""
                }`}
              />
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`block h-3 w-3 transition-transform lg:hidden ${
                  isMobileMenuOpen ? "rotate-180" : ""
                }`}
              />
            </ListItem>
          </Typography>
        </MenuHandler>
        <MenuList className="hidden max-w-screen-xl rounded-xl lg:block">
          <ul className="grid grid-cols-3 gap-y-2 outline-none outline-0">
            {renderItems}
          </ul>
        </MenuList>
      </Menu>
      <div className="block lg:hidden">
        <Collapse open={isMobileMenuOpen}>{renderItems}</Collapse>
      </div>
    </React.Fragment>
  );
}

function NavList() {
  return (
    <List className="mt-4 mb-6 p-0 lg:mt-0 lg:mb-0 lg:flex-row lg:p-1">
      <Typography
        as="a"
        href="#"
        variant="lead"
        color="blue-gray"
        className="font-medium"
      >
        <ListItem className="flex items-center gap-2 py-2 pr-4">
          Главная
        </ListItem>
      </Typography>
      <Typography
        as="a"
        href="#"
        variant="lead"
        color="blue-gray"
        className="font-medium"
      >
        <ListItem className="flex items-center gap-2 py-2 pr-4">О нас</ListItem>
      </Typography>
      <NavListMenu />
      <Typography
        as="a"
        href="#"
        variant="lead"
        color="blue-gray"
        className="font-medium"
      >
        <ListItem className="flex items-center gap-2 py-2 pr-4">
          Контакты
        </ListItem>
      </Typography>
    </List>
  );
}

export function NavbarBlock() {
  const [openNav, setOpenNav] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false),
    );
  }, []);

  return (
    <Navbar className="mx-auto max-w-screen-xl px-4 py-2">
      <div className="flex items-center justify-between text-blue-gray-900">
        <Typography
          as="a"
          href="#"
          variant="h5"
          className="mr-4 cursor-pointer py-1.5 lg:ml-2 relative group"
        >
          OPLATI.STORE
          <span className="absolute left-0 bottom-0 w-0 h-[2px] bg-current transition-all duration-300 group-hover:w-full"></span>
        </Typography>
        <div className="hidden lg:block">
          <NavList />
        </div>
        <div className="hidden gap-2 lg:flex">
          <a
            href="https://t.me/iprahka"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm text-blue-500 px-4 py-2 border border-blue-500 rounded-md hover:bg-blue-500 hover:text-white transition"
          >
            Telegram
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=16892548127"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm text-green-500 px-4 py-2 border border-green-500 rounded-md hover:bg-green-500 hover:text-white transition"
          >
            WhatsApp
          </a>
        </div>
        <IconButton
          variant="text"
          color="blue-gray"
          className="lg:hidden"
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <XMarkIcon className="h-6 w-6" strokeWidth={2} />
          ) : (
            <Bars3Icon className="h-6 w-6" strokeWidth={2} />
          )}
        </IconButton>
      </div>
      <Collapse open={openNav}>
        <NavList />
        <div className="flex w-full flex-nowrap items-center gap-2 lg:hidden">
          <a
            href="https://t.me/telegram"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm text-blue-500 px-4 py-2 border border-blue-500 rounded-md hover:bg-blue-500 hover:text-white transition w-full text-center"
          >
            Telegram
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=79000000000"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm text-green-500 px-4 py-2 border border-green-500 rounded-md hover:bg-green-500 hover:text-white transition w-full text-center"
          >
            WhatsApp
          </a>
        </div>
      </Collapse>
    </Navbar>
  );
}
